<script>
export default {
  name: 'Redirect',
  beforeRouteEnter(to, from, next) {
    // 通过 `vm` 访问组件实例
    next((vm) => {
      // 获取地址栏的token
      const token = to.hash.slice(1);
      const type = to.query.type;
      vm.initToken(token, type);
    });
  },
  beforeRouteUpdate(to, from, next) {
    const token = to.hash.slice(1);
    const type = to.query.type;
    this.initToken(token, type);
    // 路由变化时，设置
    next();
  },
  methods: {
    initToken(token, type) {
      sessionStorage.setItem('urlToken', token);
      this.$router.push({
        path: `/${ type }`
      });
    }
  }
};
</script>
